import React from "react";

export default function ChatSecondSvg() {
	return (
		<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
			<g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="主页_列表_-语音识别过程结果" transform="translate(-264.000000, -970.000000)" fill="currentColor" fillRule="nonzero">
					<path d="M269.29117,988.8242 L264,990 L265.17581,984.7088 C264.42544,983.3056 264,981.7025 264,980 C264,974.47715 268.47715,970 274,970 C279.5228,970 284,974.47715 284,980 C284,985.5228 279.5228,990 274,990 C272.2975,990 270.6944,989.5746 269.29117,988.8242 Z M269.58075,986.711 L270.23428,987.0605 C271.38248,987.6745 272.6655,988 274,988 C278.4183,988 282,984.4183 282,980 C282,975.58172 278.4183,972 274,972 C269.58172,972 266,975.58172 266,980 C266,981.3345 266.32549,982.6175 266.93949,983.7657 L267.28896,984.4192 L266.63416,987.3658 L269.58075,986.711 Z" id="形状"/>
				</g>
			</g>
		</svg>
	)
}
