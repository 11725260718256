import React from "react";

export default function LikeSvg() {
	return (
		<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
			<g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="控件" transform="translate(-244.000000, -456.000000)">
					<g id="点赞" transform="translate(244.000000, 456.000000)">
						<rect id="矩形" fill="#E0E0E0" opacity="0" x="0" y="0" width="26" height="26"/>
						<path d="M15.5998,10.3606816 L22,10.3606816 C23.1046,10.3606816 24,11.2561116 24,12.3606516 L24,14.4650516 C24,14.7262516 23.9488,14.9849516 23.8494,15.2265516 L20.755,22.7414516 C20.6007,23.1161516 20.2355,23.3606516 19.8303,23.3606516 L3,23.3606516 C2.44772,23.3606516 2,22.9129516 2,22.3606516 L2,12.3606516 C2,11.8083916 2.44772,11.3606816 3,11.3606816 L6.48184,11.3606816 C6.80677,11.3606816 7.11143,11.2028116 7.29881,10.9373616 L12.7522,3.2117066 C12.8947,3.0098376 13.1633,2.9423296 13.3843,3.0528346 L15.1984,3.9598616 C16.25,4.4856916 16.7931,5.6732716 16.5031,6.8127016 L15.5998,10.3606816 Z M8,12.9481516 L8,21.3606516 L19.1606,21.3606516 L22,14.4650516 L22,12.3606516 L15.5998,12.3606516 C14.2951,12.3606516 13.3398,11.1316316 13.6616,9.8672616 L14.5649,6.3192916 C14.6229,6.0914016 14.5143,5.8538816 14.3039,5.7487216 L13.6428,5.4181516 L8.93275,12.0907316 C8.68285,12.4447516 8.36341,12.7349516 8,12.9481516 Z M6,13.3606516 L4,13.3606516 L4,21.3606516 L6,21.3606516 L6,13.3606516 Z" id="形状备份-2" fill="currentColor" fillRule="nonzero"/>
					</g>
				</g>
			</g>
		</svg>
	)
}
