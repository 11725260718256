import React from "react";

export default function UnlikeSvg() {
	return (
		<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
			<g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="控件" transform="translate(-301.000000, -456.000000)">
					<g id="不喜欢" transform="translate(301.000000, 456.000000)">
						<rect id="矩形" fill="#E0E0E0" opacity="0" x="0" y="0" width="26" height="26"/>
						<path d="M10.40017,16 L4,16 C2.89543,16 2,15.1046 2,14 L2,11.8957 C2,11.6344 2.05118,11.3757 2.15064,11.1342 L5.24501,3.61925 C5.3993,3.24455 5.76447,3 6.16969,3 L23,3 C23.5523,3 24,3.44772 24,4 L24,14 C24,14.5523 23.5523,15 23,15 L19.5182,15 C19.1932,15 18.8886,15.1579 18.7012,15.4233 L13.2478,23.149 C13.1053,23.3508 12.8367,23.4184 12.6157,23.3078 L10.80163,22.4008 C9.74998,21.875 9.20687,20.6874 9.49694,19.548 L10.40017,16 Z M18,13.4125 L18,5 L6.83939,5 L4,11.8957 L4,14 L10.40017,14 C11.7049,14 12.6602,15.229 12.3384,16.4934 L11.4351,20.0414 C11.3771,20.2693 11.4857,20.5068 11.6961,20.612 L12.3572,20.9425 L17.0673,14.27 C17.3172,13.9159 17.6366,13.6257 18,13.4125 Z M20,13 L22,13 L22,5 L20,5 L20,13 Z" id="形状备份-3" fill="currentColor" fillRule="nonzero"/>
					</g>
				</g>
			</g>
		</svg>
	)
}
