import React from "react";

export default function SearchSvg() {
  return (
    <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="要素加工_生产任务" transform="translate(-567.000000, -105.000000)" fill="#C6CACE" fillRule="nonzero">
          <g id="搜索" transform="translate(551.000000, 92.000000)">
            <path d="M22.3333333,13.3333333 C25.6453333,13.3333333 28.3333333,16.0213333 28.3333333,19.3333333 C28.3333333,22.6453333 25.6453333,25.3333333 22.3333333,25.3333333 C19.0213333,25.3333333 16.3333333,22.6453333 16.3333333,19.3333333 C16.3333333,16.0213333 19.0213333,13.3333333 22.3333333,13.3333333 Z M22.3333333,24 C24.9113333,24 27,21.9113333 27,19.3333333 C27,16.7546667 24.9113333,14.6666667 22.3333333,14.6666667 C19.7546667,14.6666667 17.6666667,16.7546667 17.6666667,19.3333333 C17.6666667,21.9113333 19.7546667,24 22.3333333,24 Z M27.99,24.0473333 L29.876,25.9326667 L28.9326667,26.876 L27.0473333,24.99 L27.99,24.0473333 L27.99,24.0473333 Z" id="形状"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
