import React from "react";

export default function CameraSvg() {
	return (
		<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
			<g id="AI中台三期-权限管理" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<g id="权限管理-个人中心页面" transform="translate(-424.000000, -206.000000)">
					<g id="编组-4" transform="translate(420.000000, 202.000000)">
						<g id="camera-fill" transform="translate(4.500000, 4.500000)">
							<polygon id="路径" points="0 0 15 0 15 15 0 15"></polygon>
							<path d="M5.625,1.875 L9.375,1.875 L10.625,3.125 L13.125,3.125 C13.470178,3.125 13.75,3.40482203 13.75,3.75 L13.75,12.5 C13.75,12.845178 13.470178,13.125 13.125,13.125 L1.875,13.125 C1.52982203,13.125 1.25,12.845178 1.25,12.5 L1.25,3.75 C1.25,3.40482203 1.52982203,3.125 1.875,3.125 L4.375,3.125 L5.625,1.875 Z M7.5,11.875 C9.57106781,11.875 11.25,10.1960678 11.25,8.125 C11.25,6.05393219 9.57106781,4.375 7.5,4.375 C5.42893219,4.375 3.75,6.05393219 3.75,8.125 C3.75,10.1960678 5.42893219,11.875 7.5,11.875 Z M7.5,10.625 C6.11928813,10.625 5,9.50571187 5,8.125 C5,6.74428813 6.11928813,5.625 7.5,5.625 C8.88071187,5.625 10,6.74428813 10,8.125 C10,9.50571187 8.88071187,10.625 7.5,10.625 Z" id="形状" fill="#000000" fill-rule="nonzero"></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}
