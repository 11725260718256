import React from "react";

export default function ViewSvg() {
	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
			<g id="后台" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="画板" transform="translate(-703.000000, -542.000000)">
					<g id="编组-13" transform="translate(703.000000, 542.000000)">
						<g id="编组-24">
							<path d="M12.000025,5 C16.4857038,5 20.2175996,8.23313333 21,12.5 C20.2175996,16.7668333 16.4857038,20 12.000025,20 C7.51427953,20 3.78240868,16.7668333 3,12.5 C3.78240868,8.23313333 7.51427953,5 12.000025,5 Z M12.000025,18.3333333 C15.5236134,18.3333333 18.5387457,15.8766667 19.3019292,12.5 C18.5387457,9.12335833 15.5236134,6.66666667 12.000025,6.66666667 C8.47635329,6.66666667 5.4612377,9.12335833 4.69802091,12.5 C5.4612377,15.8766667 8.47635329,18.3333333 12.000025,18.3333333 Z M12.000025,16.25 C9.93249145,16.25 8.25644929,14.5710833 8.25644929,12.5 C8.25644929,10.4289333 9.93249145,8.75 12.000025,8.75 C14.0674586,8.75 15.7435673,10.4289333 15.7435673,12.5 C15.7435673,14.5710833 14.0674586,16.25 12.000025,16.25 Z M12.000025,14.5833333 C13.148627,14.5833333 14.0797707,13.6505833 14.0797707,12.5 C14.0797707,11.3494167 13.148627,10.4166667 12.000025,10.4166667 C10.851423,10.4166667 9.92024591,11.3494167 9.92024591,12.5 C9.92024591,13.6505833 10.851423,14.5833333 12.000025,14.5833333 Z" id="形状" fill="currentColor" fillRule="nonzero"/>
							<path d="M12.000025,5 C16.4857038,5 20.2175996,8.23313333 21,12.5 C20.2175996,16.7668333 16.4857038,20 12.000025,20 C7.51427953,20 3.78240868,16.7668333 3,12.5 C3.78240868,8.23313333 7.51427953,5 12.000025,5 Z M12.000025,18.3333333 C15.5236134,18.3333333 18.5387457,15.8766667 19.3019292,12.5 C18.5387457,9.12335833 15.5236134,6.66666667 12.000025,6.66666667 C8.47635329,6.66666667 5.4612377,9.12335833 4.69802091,12.5 C5.4612377,15.8766667 8.47635329,18.3333333 12.000025,18.3333333 Z M12.000025,16.25 C9.93249145,16.25 8.25644929,14.5710833 8.25644929,12.5 C8.25644929,10.4289333 9.93249145,8.75 12.000025,8.75 C14.0674586,8.75 15.7435673,10.4289333 15.7435673,12.5 C15.7435673,14.5710833 14.0674586,16.25 12.000025,16.25 Z M12.000025,14.5833333 C13.148627,14.5833333 14.0797707,13.6505833 14.0797707,12.5 C14.0797707,11.3494167 13.148627,10.4166667 12.000025,10.4166667 C10.851423,10.4166667 9.92024591,11.3494167 9.92024591,12.5 C9.92024591,13.6505833 10.851423,14.5833333 12.000025,14.5833333 Z" id="形状" fill="currentColor" fillRule="nonzero"/>
							<rect id="矩形" fillOpacity="0" fill="#D8D8D8" x="0" y="0" width="24" height="24"/>
						</g>
						<g id="eye-line-(5)备份" transform="translate(3.000000, 5.000000)"/>
					</g>
				</g>
			</g>
		</svg>
	)
}
