import React from "react";

export default function SendSvg() {
	return (
		<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
			<g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="控件" transform="translate(-288.000000, -365.000000)">
					<g id="编组-3" transform="translate(288.000000, 365.000000)">
						<g id="发送备份">
							<rect id="矩形" fill="#E0E0E0" opacity="0" x="0" y="0" width="26" height="26"/>
							<path d="M3.39211014,10.0474881 C2.86957014,9.87330805 2.86545014,9.59227805 3.40286014,9.41313805 L22.4891001,3.05106805 C23.0175001,2.87490805 23.3206001,3.17071805 23.1726001,3.68899805 L17.7194001,22.7752381 C17.5684001,23.3037381 17.2637001,23.3220381 17.0404001,22.8196381 L13.4460001,14.7321381 L19.4460001,6.73210805 L11.4460001,12.7321381 L3.39211014,10.0474881 Z" id="路径备份" fill="currentColor" fillRule="nonzero" transform="translate(13.105041, 13.092192) rotate(-360.000000) translate(-13.105041, -13.092192) "/>
						</g>
						<g id="发送_-点击" transform="translate(3.000000, 3.000000)" fill="currentColor" fillRule="nonzero">
							<path d="M0.392110138,7.04748805 C-0.130429862,6.87330805 -0.134549862,6.59227805 0.402860138,6.41313805 L19.4891001,0.0510680522 C20.0175001,-0.125091948 20.3206001,0.170718052 20.1726001,0.688998052 L14.7194001,19.7752381 C14.5684001,20.3037381 14.2637001,20.3220381 14.0404001,19.8196381 L10.4460001,11.7321381 L16.4460001,3.73210805 L8.44600014,9.73213805 L0.392110138,7.04748805 Z" id="路径备份" transform="translate(10.105041, 10.092192) rotate(-360.000000) translate(-10.105041, -10.092192) "/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}
